import orderItem from './components/order-item/index.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "profile-orders",
  components: {
    orderItem
  },
  data() {
    return {}
  },
  created() {
    if (this.$route.query.filter) {
      this.fetchOrders(this.$route.query.filter)
    } else {
      this.fetchOrders()
    }
  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      orders: `profile/orders`,
      ordersLoading: `profile/ordersLoading`
    })
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchOrders: 'profile/FETCH_ORDERS',
      fetchOrderFilter: 'profile/FETCH_ORDERS_FILTER'
    }),
    changeFilter(filter) {
      this.$router.push({name: 'profile-orders', query: {filter: filter}}).catch(() => {
        console.log('')
      })
      this.fetchOrders(filter)
    },
    all() {
      this.fetchOrders()
      this.$router.push({name: 'profile-orders', query: {filter: ''}}).catch(() => {
        console.log('')
      })
    }
  }

}