import {VueSlideToggle} from "vue-slide-toggle";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Vue from 'vue'
import Clipboard from 'v-clipboard'
import Axios from 'axios'

Vue.use(Axios)
Vue.use(Clipboard)
export default {
  name: "profile-orders",
  props: ['order'],
  data() {
    return {
      isOpen: false,
      trackNumber: '',
    }
  },
  computed: {
    ...mapGetters({
      currency: 'setting/currentCurrency',
      repeatOrderBasket: `order/repeatOrder`
    }),
    totalAmountCurrency() {
      return this.order.products.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.price * currentValue.count);
      }, 0);
    },
    totalAmountOriginal() {
      return this.order.products.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.original_price * currentValue.count);
      }, 0);
    },
  },
  components: {
    VueSlideToggle
  },
  created() {
    this.trackNumber = this.order.delivery_code
  },
  methods: {
    ...mapMutations({
      resetBasket: 'basket/RESET_BASKET',
      addBasket: `basket/ADD_ITEM_TO_BASKET`,
    }),
    ...mapActions({
      repeatOrder: `order/REPEAT_ORDER`,
    }),
    repeat() {
      this.repeatOrder(this.order.id)
      this.resetBasket()
      setTimeout(() => {
      for (let i in this.repeatOrderBasket) {
        this.addBasket({
          select_count: this.repeatOrderBasket[i].count,
          ...this.repeatOrderBasket[i]
        })
      }
      }, 500)
      setTimeout(() => {
        this.$router.push({name: 'confirm-order'}).catch(() => {
          console.log()
        })
      }, 500)
    },
    copyURL() {
      this.$clipboard(this.trackNumber);
      this.$toasted.success(this.$t('successCopy'));
    },
    downloadItem({pdf, label}) {
      Axios.get(pdf, {responseType: 'blob'})
        .then(response => {
          const blob = new Blob([response.data], {type: 'application/pdf'})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    }
  }
}